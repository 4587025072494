<template>
  <div class="content">
    <PageHeader :title="$tc('app.monitor', 2)" />
    <section class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div
            class="card"
            :class="{
              'card-expand': expanded,
            }"
          >
            <div class="card-header">
              Fornecedores
              <ul class="actions top-right">
                <li>
                  <a href="javascript:void(0)" @click="fetch"
                    ><i class="icon dripicons-clockwise"></i
                  ></a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="expand">
                    <i v-if="expanded" class="dripicons-contract-2"></i>
                    <i v-else class="icon dripicons-expand-2"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div class="card-body block-el p-0">
              <div v-if="fetched" class="responsive-table">
                <div
                  v-if="!providers.length && fetched"
                  class="text-center table-placeholder"
                >
                  <br />
                  <i class="fas fa-building font-size-80"></i>
                  <h5 class="card-title m-t-20">
                    {{ $t('adm-component.none') }}
                  </h5>
                </div>
                <table class="table">
                  <thead class="bg-light" v-if="providers.length">
                    <tr>
                      <th scope="col" class="text-left">
                        {{ $tc('generic-str.name', 1) }}
                      </th>
                      <th scope="col">Vivo (MT)</th>
                      <th scope="col">Vivo (MO)</th>
                      <th scope="col">Vivo (DLR)</th>
                      <th scope="col">TIM (MT)</th>
                      <th scope="col">TIM (MO)</th>
                      <th scope="col">TIM (DLR)</th>
                      <th scope="col">Claro (MT)</th>
                      <th scope="col">Claro (MO)</th>
                      <th scope="col">Claro (DLR)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="provider in providers" :key="provider.id">
                      <td data-label="Nome" class="text-left">
                        {{ provider.name }}
                      </td>
                      <td data-label="Apelido">
                        <div
                          class="badge"
                          :class="{
                            'badge-success':
                              provider.stats.carriers.vivo.mt < 120,
                            'badge-info':
                              provider.stats.carriers.vivo.mt >= 120 &&
                              provider.stats.carriers.vivo.mt < 1200,
                            'badge-warning':
                              provider.stats.carriers.vivo.mt >= 1200,
                          }"
                          v-if="provider.stats.carriers.vivo.mt"
                        >
                          {{ provider.stats.carriers.vivo.mt | elapsed }}
                        </div>
                        <div class="badge badge-danger" v-else>
                          {{
                            (now - provider.stats.carriers.vivo.last_message)
                              | elapsed
                          }}
                        </div>
                      </td>
                      <td data-label="Apelido">
                        <div
                          class="badge"
                          :class="{
                            'badge-success':
                              provider.stats.carriers.vivo.mo < 120,
                            'badge-info':
                              provider.stats.carriers.vivo.mo >= 120 &&
                              provider.stats.carriers.vivo.mo < 1200,
                            'badge-warning':
                              provider.stats.carriers.vivo.mo >= 1200,
                          }"
                          v-if="provider.stats.carriers.vivo.mo"
                        >
                          {{ provider.stats.carriers.vivo.mo | elapsed }}
                        </div>
                        <div class="badge badge-danger" v-else>
                          {{
                            (now - provider.stats.carriers.vivo.last_message)
                              | elapsed
                          }}
                        </div>
                      </td>
                      <td data-label="Apelido">
                        <div
                          class="badge"
                          :class="{
                            'badge-success':
                              provider.stats.carriers.vivo.dlr < 120,
                            'badge-info':
                              provider.stats.carriers.vivo.dlr >= 120 &&
                              provider.stats.carriers.vivo.dlr < 1200,
                            'badge-warning':
                              provider.stats.carriers.vivo.dlr >= 1200,
                          }"
                          v-if="provider.stats.carriers.vivo.dlr"
                        >
                          {{ provider.stats.carriers.vivo.dlr | elapsed }}
                        </div>
                        <div class="badge badge-danger" v-else>
                          {{
                            (now - provider.stats.carriers.vivo.last_message)
                              | elapsed
                          }}
                        </div>
                      </td>
                      <td scope="row" data-label="Tipo">
                        <div
                          class="badge"
                          :class="{
                            'badge-success':
                              provider.stats.carriers.tim.mt < 120,
                            'badge-info':
                              provider.stats.carriers.tim.mt >= 120 &&
                              provider.stats.carriers.tim.mt < 1200,
                            'badge-warning':
                              provider.stats.carriers.tim.mt >= 1200,
                          }"
                          v-if="provider.stats.carriers.tim.mt"
                        >
                          {{ provider.stats.carriers.tim.mt | elapsed }}
                        </div>
                        <div class="badge badge-danger" v-else>
                          {{
                            (now - provider.stats.carriers.tim.last_message)
                              | elapsed
                          }}
                        </div>
                      </td>
                      <td scope="row" data-label="Tipo">
                        <div
                          class="badge"
                          :class="{
                            'badge-success':
                              provider.stats.carriers.tim.mo < 120,
                            'badge-info':
                              provider.stats.carriers.tim.mo >= 120 &&
                              provider.stats.carriers.tim.mo < 1200,
                            'badge-warning':
                              provider.stats.carriers.tim.mo >= 1200,
                          }"
                          v-if="provider.stats.carriers.tim.mo"
                        >
                          {{ provider.stats.carriers.tim.mo | elapsed }}
                        </div>
                        <div class="badge badge-danger" v-else>
                          {{
                            (now - provider.stats.carriers.tim.last_message)
                              | elapsed
                          }}
                        </div>
                      </td>
                      <td scope="row" data-label="Tipo">
                        <div
                          class="badge"
                          :class="{
                            'badge-success':
                              provider.stats.carriers.tim.dlr < 120,
                            'badge-info':
                              provider.stats.carriers.tim.dlr >= 120 &&
                              provider.stats.carriers.tim.dlr < 1200,
                            'badge-warning':
                              provider.stats.carriers.tim.dlr >= 1200,
                          }"
                          v-if="provider.stats.carriers.tim.dlr"
                        >
                          {{ provider.stats.carriers.tim.dlr | elapsed }}
                        </div>
                        <div class="badge badge-danger" v-else>
                          {{
                            (now - provider.stats.carriers.tim.last_message)
                              | elapsed
                          }}
                        </div>
                      </td>
                      <td class="action">
                        <div
                          class="badge"
                          :class="{
                            'badge-success':
                              provider.stats.carriers.claro.mt < 120,
                            'badge-info':
                              provider.stats.carriers.claro.mt >= 120 &&
                              provider.stats.carriers.claro.mt < 1200,
                            'badge-warning':
                              provider.stats.carriers.claro.mt >= 1200,
                          }"
                          v-if="provider.stats.carriers.claro.mt"
                        >
                          {{ provider.stats.carriers.claro.mt | elapsed }}
                        </div>
                        <div class="badge badge-danger" v-else>
                          {{
                            (now - provider.stats.carriers.claro.last_message)
                              | elapsed
                          }}
                        </div>
                      </td>
                      <td class="action">
                        <div
                          class="badge"
                          :class="{
                            'badge-success':
                              provider.stats.carriers.claro.mo < 120,
                            'badge-info':
                              provider.stats.carriers.claro.mo >= 120 &&
                              provider.stats.carriers.claro.mo < 1200,
                            'badge-warning':
                              provider.stats.carriers.claro.mo >= 1200,
                          }"
                          v-if="provider.stats.carriers.claro.mo"
                        >
                          {{ provider.stats.carriers.claro.mo | elapsed }}
                        </div>
                        <div class="badge badge-danger" v-else>
                          {{
                            (now - provider.stats.carriers.claro.last_message)
                              | elapsed
                          }}
                        </div>
                      </td>
                      <td class="action">
                        <div
                          class="badge"
                          :class="{
                            'badge-success':
                              provider.stats.carriers.claro.dlr < 120,
                            'badge-info':
                              provider.stats.carriers.claro.dlr >= 120 &&
                              provider.stats.carriers.claro.dlr < 1200,
                            'badge-warning':
                              provider.stats.carriers.claro.dlr >= 1200,
                          }"
                          v-if="provider.stats.carriers.claro.dlr"
                        >
                          {{ provider.stats.carriers.claro.dlr | elapsed }}
                        </div>
                        <div class="badge badge-danger" v-else>
                          {{
                            (now - provider.stats.carriers.claro.last_message)
                              | elapsed
                          }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <br />
                <pagination :lastPage="pages" @change="fetch" />
              </div>
              <div v-else class="qt-block-ui relative" style="padding: 120px" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import PageHeader from '@/components/PageHeader.vue';
import ProviderService from '@/services/admin/provider.service';
import Pagination from '@/components/Pagination.vue';

export default {
  name: 'Providers',
  components: {
    PageHeader,
    Pagination,
  },
  data() {
    return {
      fetched: false,
      expanded: false,
      providers: [],
      form: {
        sms: true,
        active: true,
        name: '',
        page: 1,
      },
      pages: 1,
      running: false,
      updating: false,
      timeout: 0,
    };
  },
  mounted() {
    this.running = true;
    this.fetch(1);
  },
  beforeDestroy() {
    this.running = false;
    clearTimeout(this.timeout);
  },
  methods: {
    reFeatch() {
      if (this.running) {
        this.timeout = setTimeout(() => this.fetch(), 60000);
      }
    },
    fetch(page = 1) {
      this.now = moment().unix();
      this.form.page = page;
      this.fetched = false;
      ProviderService.getProviders(this.form).then((response) => {
        this.fetched = true;
        this.providers = response.data;
        this.pages = response.last_page;
        return this.reFeatch();
      }).then((response) => this.refresh()).catch((error) => {
        this.content = error;
      });
    },
    refresh() {
      return this.$store.dispatch('auth/refresh');
    },
    expand() {
      this.expanded = !this.expanded;
    },
  },
};
</script>
<style scoped lang="scss">
.btn-remove {
  color: #fff;
  margin-bottom: 5px;
}
.btn-new {
  margin-bottom: 5px;
}
.color-white {
  color: #fff;
}
.form-search {
  float: right;
  width: 100%;
}
.form-search button {
  border: solid 1px #ccc;
  border-left: 0px;
  text-align: center;
  border-radius: 0px 10px 10px 0px;
  height: 35px;
}
.form-search button i {
  font-size: 16px;
}
.w-100 {
  width: 100% !important;
}
</style>
